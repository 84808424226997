import React from "react"
import Layout from "../components/layout"

export default () => {
  return (
    <Layout highlight="Art">
      bluh
    </Layout>
  );
}
